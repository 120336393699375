function recaptchaCallback() {
    $('#f_recaptcha').valid();
}

$(document).ready(function() {

  $('.toggleMenu').click(function(){
    $('ul').toggleClass('menuOpen');
    $('.toggleMenu').toggleClass('menuOpen');
  });

  $('.menu li').click(function() {
    $(this).toggleClass('active');
   // $('li').removeClass('active');
  });

  function accordeonHeightCompetence(accordeonLink){
    // recupère la height
    var height = accordeonLink.parent().find('.accordeonText').outerHeight(true);


    // re appliquer la height de base
    if (accordeonLink.parent().find('.accordeon').hasClass('open')) {
        // applique la height sur l'élément voulu
        accordeonLink.parent().find('.accordeon').css('height', height);
    } else {
        accordeonLink.parent().find('.accordeon').css('height', '0');
    }
  }

  $('.accordeonLink').click(function() {
    var accordeonLink = $(this);

    accordeonLink.toggleClass('open');
    accordeonLink.parent().find('.accordeon').toggleClass('open');

    accordeonHeightCompetence(accordeonLink);
  });

  $(window).resize(function() {
    $('.open').each(function(){
      accordeonHeightCompetence($(this));
    });
  });


  function accordeonHeightContact(){
    $('.address').each(function(){
      var height = $(this).find('.accordeonAddressContainer').outerHeight(true);
      var maskTarget = $(this).find('.accordeonAddressMask');

      if ($(this).hasClass('open')) {
        maskTarget.css('height', height);
      } else {
        maskTarget.css('height', '0');
      }
    });
  }

  $('.address').first().addClass('open');
  accordeonHeightContact();

  $('.address').click(function() {
    $('.address').removeClass('open');
    $(this).toggleClass('open');

    accordeonHeightContact();
  });

  $(window).resize(accordeonHeightContact);

  // POP UP
  $('.btnSlider').click(function() {
      var id_popup = $(this).attr("data-popup");
    $('.containerPopup').each(function() {
        if ($(this).attr("data-popup") == id_popup) {
            $(this).css('display', 'flex');
        }
    });
  })

  $('.closeCross').click(function() {
    $('.containerPopup').css('display', 'none');
  })

  //  HOMEPAGE SLIDER

  $('.homeSlider').owlCarousel({
    loop: true,
    items: 1,
    dots: true,
    nav: false,
    dotsContainer: '#owl-townContainer',
    responsiveClass: true,
    autoplay: true,
    autoplayTimeout:15000,
    responsive:{
      0:{
        dots: false,
        nav: true,
      },
      1199:{
        dots: true,
      }
    }
  });

  $('.triggerCarousel').click(function () {
    $('.homeSlider').trigger('to.owl.carousel', [$(this).index(), 300]);
  });

  // DOUBLE SLIDER QUI SOMMES NOUS?

  var sync1 = $("#sync1");
  var sync2 = $("#sync2");
  var slidesPerPage = 10;
  var syncedSecondary = true;

  sync1.owlCarousel({
    items : 1,
    nav: true,
    //navText: ["<img src='img/leftArrow.png'>","<img src='img/rightArrow.png'>"],
    autoplay: false,
    dots: false,
    loop: true,
    smartSpeed: 2000,
  }).on('changed.owl.carousel', syncPosition);

  sync2
    .on('initialized.owl.carousel', function () {
      sync2.find(".owl-item").eq(0).addClass("current");
    })
    .owlCarousel({
    items : slidesPerPage,
    dots: false,
    nav: false,
    margin: 10,
    freeDrag: true,
    slideBy: slidesPerPage,
    smartSpeed: 2000,
    responsiveRefreshRate : 100
  }).on('changed.owl.carousel', syncPosition2);

  function syncPosition(el) {
    //if you set loop to false, you have to restore this next line
    //var current = el.item.index;

    //if you disable loop you have to comment this block
    var count = el.item.count-1;
    var current = Math.round(el.item.index - (el.item.count/2) - .5);



    //end block

    sync2
      .find(".owl-item")
      .removeClass("current")
      .eq(current)
      .addClass("current");
    var onscreen = sync2.find('.owl-item.active').length - 1;
    var start = sync2.find('.owl-item.active').first().index();
    var end = sync2.find('.owl-item.active').last().index();


  }

  function syncPosition2(el) {
    if(syncedSecondary) {
      var number = el.item.index;
      sync1.data('owl.carousel').to(number, 100, true);
    }
  }

  sync2.on("click", ".owl-item", function(e){
    e.preventDefault();
    var number = $(this).index();
    sync1.data('owl.carousel').to(number, 300, true);
  });

  // RECAPTCHA

      $.validator.addMethod("validateRecaptcha", function (value, element) {
          if (grecaptcha.getResponse() == '') {
              return false;
          } else {
              return true;
          }
      }, "Vous devez valider le reCAPTCHA");

    setTimeout(function () {
      if ($('#container_form form').length) {
          $('#contactRgpd').rules('add', {
                validateCgv: true
            });
          $('#f_recaptcha').rules('add', {
              validateRecaptcha: true
          });
        }
    }, 100);

    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide."
    });
    $.validator.addMethod("validateCgv", function (value, element) {
            return $('#contactRgpd').is(':checked');
        }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $("form").submit(function() {
        $(this).validate({
            errorElement: "span",
            errorPlacement: function (error, element) {
                error.addClass("help-block");
                if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                    var parentElm = element.closest('.form-group');
                    error.addClass("checkbox");
                    parentElm.append(error);
                } else if (element.attr('type') === undefined) {
                    var parentElm = element.closest('.form-group');
                    error.addClass("select");
                    parentElm.append(error);
                }
                else
                    error.insertAfter(element);
            },
            highlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
            },
            submitHandler: function (form) {
                form.submit();
            }
        });


    });


    if ($(window).width() > 991) {
      $('.paymentModuleMobile').find('form').attr('name','');
      $('.paymentModuleDesktop').find('form').attr('name','formulaire');
    }

    if ($(window).width() < 991) {
      $('.paymentModuleDesktop').find('form').attr('name','');
      $('.paymentModuleMobile').find('form').attr('name','formulaire');
    }

    $(window).resize(function() {
      if ($(window).width() > 991) {
        $('.paymentModuleMobile').find('form').attr('name','');
        $('.paymentModuleDesktop').find('form').attr('name','formulaire');
      }

      if ($(window).width() < 991) {
        $('.paymentModuleDesktop').find('form').attr('name','');
        $('.paymentModuleMobile').find('form').attr('name','formulaire');
      }
    });


  // Carousel synchronisé ventes immobilières

  // DOUBLE SLIDER QUI SOMMES NOUS?

  var IDnumber = 1;

  $('.owl-property').each(function() {

		var newID = 'sync1Property' + IDnumber;
		$(this).attr('id', newID);

		var newIDNext = 'sync2Property' + IDnumber;
		$(this).next().attr('id', newIDNext);
		IDnumber++;



		var sync1 = $('#' + newID);
		var sync2 = $('#' + newIDNext);
		var slidesPerPage = 4;
		var syncedSecondary = true;

		sync1.owlCarousel({
			items : 1,
			nav: false,
			//navText: ["<img src='img/leftArrow.png'>","<img src='img/rightArrow.png'>"],
			autoplay: false,
			dots: false,
			loop: true,
			smartSpeed: 2000,
		}).on('changed.owl.carousel', syncPosition);

		sync2
			.on('initialized.owl.carousel', function () {
			sync2.find(".owl-item").eq(0).addClass("current");
			})
			.owlCarousel({
			items : slidesPerPage,
			dots: false,
			nav: false,
			margin: 10,
			slideBy: slidesPerPage,
			smartSpeed: 2000,
			responsiveRefreshRate : 100
		}).on('changed.owl.carousel', syncPosition2);

		function syncPosition(el) {
			//if you set loop to false, you have to restore this next line
			//var current = el.item.index;

			//if you disable loop you have to comment this block
			var count = el.item.count-1;
			var current = Math.round(el.item.index - (el.item.count/2) - .5);



			//end block

			sync2
			.find(".owl-item")
			.removeClass("current")
			.eq(current)
			.addClass("current");
			var onscreen = sync2.find('.owl-item.active').length - 1;
			var start = sync2.find('.owl-item.active').first().index();
			var end = sync2.find('.owl-item.active').last().index();


		}

		function syncPosition2(el) {
			if(syncedSecondary) {
			var number = el.item.index;
			sync1.data('owl.carousel').to(number, 100, true);
			}
		}

		sync2.on("click", ".owl-item", function(e){
			e.preventDefault();
			var number = $(this).index();
			sync1.data('owl.carousel').to(number, 300, true);
		});
  });

	// Vente immobilière accordeon
	$('.accordeonLinkProperty').click(function() {
		$(this).addClass('hide');
		var myParent = $(this).parents('.rowProperty');

		myParent.find('.hideBtn').addClass('show');

		var heightText = myParent.find('.hideText').outerHeight(true);
		var heightForm = myParent.find('.blockForm').outerHeight(true);

		myParent.find('.containerHideText').css('height', heightText);
		myParent.find('.containerBlockForm').css('height', heightForm);
	});

	$('.hideBtn').click(function() {
		$(this).removeClass('show');
		var myParent = $(this).parents('.rowProperty');

		myParent.find('.accordeonLinkProperty').removeClass('hide');

		myParent.find('.containerHideText').css('height', '0');
		myParent.find('.containerBlockForm').css('height', '0');
	});

  // Footer
  heightCardFooter();
  $(window).resize(heightCardFooter());
});

function heightCardFooter() {
    var heightCards = 0; 

    $('.lieux').each(function() {
        var heightCardFooter = $(this).outerHeight();

        if(heightCards < heightCardFooter) {
            heightCards = heightCardFooter; 
        }
    });

    if (window.matchMedia("(min-width: 575px)").matches) {
        $('.lieux').css('height', heightCards); 
    }
}